import React from "react";
import { Link } from "gatsby";
import CatArticleCard from "./CatArticle-card";

function CoctelesconTodo({ articles }) {
  return (
    <>
      <div className="container m-4 pb-2 text-center">
        <h5 className="uppercase">cocteles con</h5>
        <h1 className="uppercase text-deliciousapple">Boone’s</h1>
      </div>
      <div className="grid grid-cols-1 gap-6 text-deliciousapple md:grid-cols-2 lg:grid-cols-3">
        {articles.slice(0, 3).map((article) => (
          <CatArticleCard
            key={article.title}
            article={article}
            className="overflow-hidden rounded-lg border border-exoticberry bg-black shadow-md transition-shadow hover:shadow-md"
          />
        ))}
      </div>
      <Link to="/category/cocteleria">
        <button className="mx-auto my-5 block rounded bg-deliciousapple py-2 px-4 uppercase text-white md:w-1/2">
          Ver todos los cocteles
        </button>
      </Link>
    </>
  );
}

export default CoctelesconTodo;
