import React from "react";
import Layout from "../components/Layout";
import { StaticImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import Coctelescon from "../components/CoctelesconTodo";
import CarruselCompra from "../components/CarruselCompra";
import BribeRecetas from "../components/BribeRecetas";
import CarruselSabores from "../components/CarruselSabores";
import Seo from "../components/Seo";

function cocteles() {
  const { strapiCategory } = useStaticQuery(graphql`
    query {
      strapiCategory(name: { eq: "Cocteleria" }) {
        articles {
          id
          slug
          title
          description
          cover {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(aspectRatio: 1.77)
              }
            }
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <Seo
        seo={{
          metaTitle: "Cocteles",
          metaDescription:
            "Recetas de Cocteles y Bebidas Preparadas con Boone´s",
        }}
      />
      <header>
        <StaticImage
          src="../assets/images/cocteles/banner-preparar-cocteles.png"
          alt="Cocteles con Boones"
          placeholder="tracedSVG"
          layout="fullWidth"
        />
        <div className="container relative -mt-5 mb-20 md:-mt-16">
          <div className="z-10 flex flex-wrap justify-center gap-4">
            <div className="max-w-xl space-x-4 space-y-2 rounded-xl bg-white p-5 text-center drop-shadow-xl">
              <h1 className="text-center capitalize text-deliciousapple">
                ¡Cocteles con Boone’s!
              </h1>
              <p className="max-w-prose font-light">
                ¡Siempre hay un sabor para cada ocasión! Con los sabores de
                Boone´s como base, puedes preparar una gran variedad de cocteles
                con los que pasarás momentos increíbles con tus amigos, con tu
                pareja o con tu familia.
              </p>
              <p className="max-w-prose font-light">
                ¿Te gustaría aprender a preparar cocteles? ¡Con estos 3 simples
                pasos puedes crearlo!
              </p>
            </div>
          </div>
        </div>
      </header>
      <main className="container">
        <div className="flex items-center justify-center gap-2 space-x-2">
          <div>
            <StaticImage
              src="../assets/images/cocteles/prepararcocteles-1.png"
              alt="Cocteles Paso 1"
              placeholder="tracedSVG"
            />
          </div>
          <div>
            <h2 className="mb-5 text-2xl uppercase text-exoticberry md:text-3xl">
              paso 1: elegir tu Boone´s
            </h2>
            <ul className="list-inside list-disc marker:text-exoticberry">
              <li>
                <Link to="/sabores/pink-lemonade">Pink Lemonade</Link>
              </li>
              <li>
                <Link to="/sabores/exotic-berry">Exotic Berry</Link>
              </li>
              <li>
                <Link to="/sabores/sunpeakpeach">Sun Peak Peach</Link>
              </li>
              <li>
                <Link to="/sabores/strawberry-hill">Strawberry Hill</Link>
              </li>
              <li>
                <Link to="/sabores/delicious-apple">Delicious Apple</Link>
              </li>
              <li>
                <Link to="/sabores/cool-raspberry">Cool Raspberry</Link>
              </li>
              <li>
                <Link to="/sabores/tropical">Tropical</Link>
              </li>
              <li>
                <Link to="/sabores/sangria">Sangría</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex items-center justify-center gap-2 space-x-2">
          <div>
            <StaticImage
              src="../assets/images/cocteles/prepararcocteles-2.png"
              alt="Cocteles Paso 2"
              placeholder="tracedSVG"
            />
          </div>
          <div>
            <h2 className="mb-5 text-2xl uppercase text-deliciousapple md:text-3xl">
              paso 2: elegir tu mezclador
            </h2>
            <ul className="list-inside list-disc marker:text-deliciousapple">
              <li>Tequila</li>
              <li>Vodka</li>
              <li>Ron</li>
              <li>Whisky</li>
              <li>Jugo</li>
              <li>Refresco</li>
            </ul>
          </div>
        </div>
        <div className="flex items-center justify-center gap-2 space-x-2">
          <div>
            <StaticImage
              src="../assets/images/cocteles/prepararcocteles-3.png"
              alt="Cocteles Paso 3"
              placeholder="tracedSVG"
            />
          </div>
          <div>
            <h2 className="mb-5 text-2xl uppercase text-tropical md:text-3xl">
              paso 3: elegir tus toppings
            </h2>
            <ul className="list-inside list-disc marker:text-tropical">
              <li>Dulces</li>
              <li>Frutas</li>
              <li>Chocolate</li>
              <li>Crema Batida</li>
              <li>Hierbas</li>
            </ul>
          </div>
        </div>
        <div className="my-20 grid justify-center text-center">
          <h2 className="text-deliciousapple">¡Listo!</h2>
          <p className="max-w-prose font-light">
            Lo mejor es que puedes mezclar una gran variedad de ingredientes
            para darles un twist mucho más refrescante y divertido, porque con
            Boone´s es #ImposibleEquivocarse
          </p>
        </div>
        <div className="mt-4">
          <Coctelescon articles={strapiCategory.articles} />
        </div>
        <div className="mt-16">
          <CarruselCompra />
        </div>
        <div className="mt-16">
          <BribeRecetas />
        </div>
        <div className="mt-16">
          <CarruselSabores />
        </div>
      </main>
    </Layout>
  );
}

export default cocteles;
