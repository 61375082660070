import React from "react";
import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const CatArticleCard = ({ article }) => {
  return (
    <Link
      to={`/${article.slug}`}
      className="overflow-hidden rounded-lg bg-white shadow-md transition-shadow hover:shadow-2xl"
    >
      <GatsbyImage
        image={getImage(article.cover?.localFile)}
        alt={article.cover?.alternativeText}
      />
      <div className="px-4 py-4">
        <h3 className="font-bold text-current">{article.title}</h3>
        <p className="mt-2 mb-2 text-neutral-500 line-clamp-2">
          {article.description}
        </p>
      </div>
    </Link>
  );
};

export const query = graphql`
  fragment CatArticleCard on STRAPI_CATEGORY {
    articles {
      id
      slug
      title
      description
      cover {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(aspectRatio: 1.77)
          }
        }
      }
    }
  }
`;
export default CatArticleCard;
